// Import Statements
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

// Image Imports
import img1 from '../../assets/storage/ckeditor/9_1695201927.png';
import img2 from '../../assets/storage/ckeditor/10_1695201946.png';
import img3 from '../../assets/storage/ckeditor/11_1695201967.png';
import img4 from '../../assets/storage/ckeditor/image_50397185_1699027208.jpg';
import food from '../../assets/img/food.jpg';
import ott from '../../assets/img/ott.jpg';
import cake from '../../assets/img/cake.jpg';
import bouq from '../../assets/img/bouq.png';
import banner1 from "../../assets/img/hero_image.jpg";
import banner2 from "../../assets/img/jpegmini_optimized (1)/IMG_3117.jpg";
import banner3 from "../../assets/storage/banners/191698950642.jpg";
import Loader from '../../components/Loader';
import axios from "axios";

const HomeScreen = () => {
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchWelcomeMessage() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}`);
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching welcome message:', error);
        // Handle error
      } 
    }
    fetchWelcomeMessage();
  }, []);

  useEffect(() => {
    Aos.init({ duration: 300 });
  }, []);

  // useEffect(() => {
  //   import('../../assets/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js')
  //     .then((module) => {
  //       console.log('Script loaded successfully');
  //     })
  //     .catch((err) => {
  //       console.error('Failed to load the script', err);
  //     });
  // }, []);

  // if (loading) {
  //   return (<Loader />);
  // }

  // if (error) {
  //   return (
  //     <div className="">
  //       <p className="">Error: {error}</p>
  //     </div>
  //   );
  // }

  return (
<div>
  
  <div id="main-slider" className="dl-slider">
 
    <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
      >
        <SwiperSlide>
        <div className="single-slide">
      <div className="bg-img kenburns-top-right" style={{backgroundImage:`url(${banner2})`,backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',}} />
      <div className="overlay" />
      <div className="slider-content-wrap d-flex align-items-center text-left">
        <div className="container">
          <div className="slider-content">
            <div className="inner-layer">
              <div data-animation="fade-in-top" data-delay="1s">Lets Celebrate your Birthday</div>
            </div>
            <div className="dl-caption big">
              <div className="inner-layer">
                <div data-animation="fade-in-bottom" data-delay="2.5s">Party with Us</div>
              </div>
            </div>
            <div className="dl-caption small">
              <div className="inner-layer">
                <div data-animation="fade-in-bottom" data-delay="3s">Welcome to A Party Theatre, where extraordinary experiences await you.<br />
                  Private theatre and event celebration experience.</div>
              </div>
            </div>
            <div className="dl-btn-group">
              <div className="inner-layer">
                <Link to="/BookNow" className="dl-btn" data-animation="fade-in-left" data-delay="3.5s" >Book Now<i className="arrow_right">&nbsp;</i></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="single-slide">
        <div className="bg-img kenburns-top-right" style={{backgroundImage:`url(${banner1})`,backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',}} />
      <div className="overlay" />
      <div className="slider-content-wrap d-flex align-items-center text-left">
        <div className="container">
          {/* <div className="slider-content">
            <div className="inner-layer">
              <div data-animation="fade-in-top" data-delay="1s">Lets Celebrate your Anniversary&nbsp;</div>
            </div>
            <div className="dl-caption big">
              <div className="inner-layer">
                <div data-animation="fade-in-bottom" data-delay="2.5s">Party with Us</div>
              </div>
            </div>
            <div className="dl-caption small">
              <div className="inner-layer">
                <div data-animation="fade-in-bottom" data-delay="3s">Welcome to Party Theatre, your premier destination for an extraordinary<br />
                  private theatre and event celebration experience.</div>
              </div>
            </div>
            <div className="dl-btn-group">
              <div className="inner-layer">
              <Link to="/BookNow" className="dl-btn" data-animation="fade-in-left" data-delay="3.5s" >Book Now<i className="arrow_right">&nbsp;</i></Link>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="single-slide">
        <div className="bg-img kenburns-top-right" style={{backgroundImage:`url(${banner3})`,backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',}} />
      <div className="overlay" />
      <div className="slider-content-wrap d-flex align-items-center text-left">
        <div className="container">
          <div className="slider-content">
            <div className="dl-caption medium">
              <div className="inner-layer">
                <div data-animation="fade-in-left" data-delay="1s">Lets Celebrate your</div>
              </div>
            </div>
            <div className="dl-caption big">
              <div className="inner-layer">
                <div data-animation="fade-in-right" data-delay="2s">Bride to be&nbsp;</div>
              </div>
            </div>
            <div className="dl-caption big">
              <div className="inner-layer">
                <div data-animation="fade-in-right" data-delay="2.5s">Party with Us</div>
              </div>
            </div>
            <div className="dl-caption small">
              <div className="inner-layer">
                <div data-animation="fade-in-right" data-delay="3s">Welcome to Party Theatre, your premier destination for an extraordinary<br />
                  private theatre and event celebration experience.</div>
              </div>
            </div>
            <div className="dl-btn-group">
              <div className="inner-layer">
              <Link to="/BookNow" className="dl-btn" data-animation="fade-in-left" data-delay="3.5s" >Book Now<i className="arrow_right">&nbsp;</i></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        </SwiperSlide>
      </Swiper>
  </div>
  <section className="service-section padding">
  <div className="container">
  <div className="row services-list">
  <div className="col-lg-4 col-sm-6 sm-padding">
  <div data-aos="fade-right">
    <div className="service-item box-shadow text-center">
      <img alt="ABOUT US" src={img1} />
      <h3>Birthday Party</h3>
      <p style={{ color: '#000' }}>Illuminate your birthday with glamour and grandeur! Experience the wonders of our private theatre splendor, where your favorite movies and shows take center stage for a celebration like no other.</p>
    </div>
  </div>
</div>
    <div className="col-lg-4 col-sm-6 sm-padding" data-aos="fade-up" data-aos-delay="300">
      <div className="service-item box-shadow text-center">
        <img alt="Anniversary" src={img2} />
        <h3>Anniversary</h3>
        <p style={{color: '#000'}}>Celebrate your milestone anniversary with a touch of romance! Discover the beauty of love and intimacy in our private theatre affair, where every moment resonates with splendor.</p>
      </div>
    </div>
    <div className="col-lg-4 col-sm-6 sm-padding" data-aos="fade-up" data-aos-delay="400">
      <div className="service-item box-shadow text-center">
        <img alt="Party & Events" src={img3} />
        <h3>Party &amp; Events</h3>
        <p style={{color: '#000'}}>Whether it's a reunion, farewell, or any social soiree in between, our private theatres offer an unmatched and customized setting for your exceptional event.</p>
        <p>&nbsp;</p>
      </div>
    </div>
  </div>
</div>
  </section>
  {/* Section 2 ENDs  */}{/* About Section Starts  */}
  <section className="content-section padding">
    <div className="container">
      <div className="row content-wrap">
        <div className="col-lg-6 sm-padding">
          <div className="content-info "  data-aos="fade-right" data-aos-delay="300"><span>About Us</span>
            <h2>Welcome to Party Theatre </h2>
            <p className="text-white">Where Dreams Unfold on the Big Screen! Your Exclusive Private Theatre Awaits, Offering Not Only Movie Magic but also a Canvas for Celebrations. Elevate Every Occasion with Dolby Atmos Surround Sound and Enchanting 4K Clarity.</p>
            <a className="default-btn" href="about-us.html">Know More</a></div>
        </div>
        <div className="col-lg-6 sm-padding wow fadeInLeft" data-wow-delay="100ms">Explore The Features</div>
      </div>
    </div>
  </section>
  {/* <div className="cta-section padding">
    <div className="container">
      <div className="cta-content text-center">
        <h2 className="wow fadeInUp" data-aos="fade-up" data-aos-delay="400">Choose the occasion, give us the requirements<br />
          and leave it to us. We will deliver the best.</h2>
        <a className="default-btn wow fadeInUp" data-wow-delay="500ms" href="theaters-in-hyderabad.html">Book Now</a></div>
    </div>
  </div> */}
  <section className="content-section padding">
    <div className="container">
      <div className="row content-wrap">
        <div className="col-lg-6 sm-padding " data-aos="fade-right" ><img alt="content" src={img4} style={{width: 921, height: 518}} /></div>
        <div className="col-lg-6 sm-padding">
          <div className="content-info " data-aos="fade-left" data-aos-delay="300ms"><span>Explore The Features</span>
            <p className="text-white">Party Theatre, we're not just a venue; we're an experience! Prepare to be dazzled in your own private theatre haven. Imagine immersing yourself in the magic of Dolby Atmos sound, where every note and whisper comes alive. The brilliance of enhanced 4K visuals will sweep you into a world of cinematic luxury like never before</p>
            <a className="default-btn" href="contact-us.html">Contact Us</a></div>
        </div>
      </div>
    </div>
  </section>
  {/* Our Clients  Ends*/}
  <p>&nbsp;</p>
  {/* TESTIMONIAL SECTION STARTS */}
  <section className="testimonial-section bg-grey padding">
    <div className="dots" />
    <div className="container">
      <div className="section-heading text-center mb-40 " data-aos="fade-left" data-aos-delay="100ms">
        <span>Testimonial</span>
        <h2>What people says</h2>
      </div>
      <div id="testimonial-carousel" className="testimonial-carousel box-shadow owl-carousel">
      </div>
    </div>
  </section>
  {/* TESTIMONIAL SECTION ENDs */}
  <section className="service-section padding" style={{background: '#fff'}}>
    <div className="container">
      <div className="row services-list  padding-15">
        <div className="col-lg-3 col-sm-6 col-6 sm-padding">
          <div className="service-item box-shadow text-center" data-aos="fade-up" data-aos-delay="100">
            <img src={food} className="img-service" alt="food" />
            <h3>Food </h3>
            <p>Enjoy delicious in-theatre dining options at affordable prices!</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 col-6 sm-padding">
          <div className="service-item box-shadow text-center" data-aos="fade-up" data-aos-delay="300">
            <img src={ott} className="img-service" alt="ott" />
            <h3>Screening</h3>
            <p>Bring your own OTT accounts and relive the theatre magic!</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 col-6 sm-padding">
          <div className="service-item box-shadow text-center wow fadeInRight" data-aos="fade-up" data-aos-delay="500">
            <img src={cake} className="img-service" alt="cake" />
            <h3>Cakes</h3>
            <p>Choose the perfect cake for your celebration from our selection.</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6  col-6 sm-padding">
          <div className="service-item box-shadow text-center wow fadeInDown" data-aos="fade-up" data-aos-delay="700">
            <img src={bouq} className="img-service" alt="bouq" />
            <h3>Bouquets</h3>
            <p>Add a beautiful rose bouquet to enhance your celebration.</p>
          </div>
        </div>
      </div>
    </div>
  </section>    
  <section className="contact-section bg-grey padding">
    <div className="dots" />
    <div className="container">
      <div className="contact-wrap d-flex align-items-center row">
        <div className="col-md-6 ">
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.8018411056914!2d78.51662817429212!3d17.37326570321349!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4aa27cb527488f9%3A0x60fb22eaf7fff7bc!2sTapes%20N%20Tales!5e0!3m2!1sen!2sin!4v1698305179946!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>            </div> */}
          {/* <iframe src="https://maps.google.com/maps?width=100%25&height=600&hl=en&q=party%20theatre,uppal+(party%20Theatre)&t=&z=15&ie=UTF8&iwloc=B&output=embed" width="100%" height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />          */}
          <div style={{width:"100%"}}>
  <iframe
    width="100%"
    height="600"
    frameBorder="0"
    marginHeight="0"
    marginWidth="0"
    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Party%20Theater,%20Surya%20nagar,%20uppal+(A%20Party%20Theatre)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
  >
    <a href="https://www.gps.ie/">gps tracker sport</a>
  </iframe>
</div>
           </div>
        <div className="col-md-6 ">
          <div className="contact-info text-center">
            <h2 style={{color: '#000'}}>Now At Your Convenient Location <br /> Hyderabad</h2>
            <a className="default-btn" href="theaters-in-hyderabad.html"> Book Now </a>
            <br />
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*<div class="sponsor-section bg-grey">*/}
  {/*    <div class="dots"></div>*/}
  {/*    <div class="container">*/}
  {/*        <div id="sponsor-carousel" class="sponsor-carousel owl-carousel">*/}
  {/*            */}
  {/*        </div>*/}
  {/*    </div>*/}
  {/*</div>*/}
  {/*<div class="icon-bar">*/}
  {/*    */}
  {/*    <a href="https://wa.me/9988-343-343" class="whatsapp"><i class="fab fa-whatsapp"></i></a>*/}
  {/*    */}
  {/*    */}
  {/*    <a href="tel:9988-343-343" class="twitter"><i class="fa fa-phone"></i></a>*/}
  {/*    */}
  {/*    */}
  {/*    <a href="https://instagram.com/_tapesntales__?igshid=MzRlODBiNWFlZA==" target="_blank" class="google"><i class="fab fa-instagram"></i></a>*/}
  {/*    */}
  {/*    */}
  {/*</div>*/}
{/* index.php  29 Nov 2019 03:30:38 GMT */}
</div>

  )
}

export default HomeScreen