import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addItem,
  selectTotalAmount,
  updateDecorationId,
  removeDecoration,
} from "../../Redux/reducer";
import "./theatre.css";
// import Aos from "aos";
import "react-datepicker/dist/react-datepicker.css";
// import "aos/dist/aos.css";

import BirthDay from "../../assets/img/decoration/birthday1698948396.jpg";
import Mom from "../../assets/img/decoration/mom to be1698948814.jpg";
import Anniversary from "../../assets/img/decoration/annivesary1698948423.jpg";
import Bride from "../../assets/img/decoration/bride to be1698948439.jpg";
import Date from "../../assets/img/decoration/date1698948842.jpg";
import BonVoyage from "../../assets/img/decoration/Bonvoyage1703420929.jpg";
import BabyShower from "../../assets/img/decoration/babyshower1697537562.jpg";
import Congratulation from "../../assets/img/decoration/congrats1698948959.webp";
import Farewell from "../../assets/img/decoration/farewell1698948828.jpg";
import Groom from "../../assets/img/decoration/groom to be1698948773.jpg";
import LoveProposal from "../../assets/img/decoration/love proposal1698948865.jpg";
import MarriageProposal from "../../assets/img/decoration/marriage propasal1698948856.jpg";
import { all } from "axios";
const Decoration = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const itemList = useSelector((state) => state.item.itemList);
  const presentAmount = useSelector(selectTotalAmount);
  const DecorationId = useSelector((state) => state.item.decorationId);
  const selectedSlot = useSelector((state) => state.item.selectedSlot);
  const [totalAmount, setTotalAmount] = useState(presentAmount);
  const [selectedDecoration, setSelectedDecoration] = useState(DecorationId);
  const [inputValues, setInputValues] = useState({});
  const decorations = [
    {
      imgSrc: BirthDay,
      title: "Birthday",
      price: 399,
      inputFields: ["Birthday Boy Name"],
    },
    {
      imgSrc: Mom,
      title: "Mom to be",
      price: 399,
      inputFields: ["Mom Name"],
    },
    {
      imgSrc: Bride,
      title: "Bride to be",
      price: 399,
      inputFields: ["Bride Name"],
    },
    {
      imgSrc: BonVoyage,
      title: "Bon Voyage",
      price: 399,
      inputFields: ["Person1 Name"],
    },
    {
      imgSrc: Date,
      title: "Date",
      price: 399,
      inputFields: ["Person1", "Person2"],
    },
    {
      imgSrc: Anniversary,
      title: "Anniversary",
      price: 399,
      inputFields: ["Person1", "Person2"],
    },
    {
      imgSrc: LoveProposal,
      title: "Love Proposal",
      price: 399,
      inputFields: ["Person1", "Person2"],
    },
    {
      imgSrc: MarriageProposal,
      title: "Marriage Proposal",
      price: 399,
      inputFields: ["Person1", "Person2"],
    },
    {
      imgSrc: Groom,
      title: "Groom to Be",
      price: 399,
      inputFields: ["Name of Groom"],
    },
    {
      imgSrc: Farewell,
      title: "Farewell",
      price: 399,
      inputFields: ["Name of Person"],
    },
    {
      imgSrc: Congratulation,
      title: "Congratulation",
      price: 399,
      inputFields: ["Name of Person"],
    },
    {
      imgSrc: BabyShower,
      title: "Baby Shower",
      price: 399,
      inputFields: ["Name of Baby"],
    },
  ];

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("slotselected", "false");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const isSetItemTrue = localStorage.getItem("slotselected") === "true";
    if (!isSetItemTrue || !selectedSlot) {
      navigate("/BookNow");
    }
  }, []);

  const handleCheckboxChange = (index) => {
    if (selectedDecoration === index) return;

    let newTotalAmount = totalAmount;
    if (selectedDecoration !== null)
      newTotalAmount -= decorations[selectedDecoration].price;

    setSelectedDecoration(index);
    setTotalAmount(newTotalAmount + decorations[index].price);
    setInputValues({});
  };

  const handleInputChange = (event, inputField) => {
    let { value } = event.target;
    setInputValues({
      ...inputValues,
      [inputField]: value,
    });
  };

  const handleNextButtonClick = () => {
   
    if (selectedDecoration != null ) {
      const allFieldsFilled = decorations[selectedDecoration].inputFields.every(
        (field) => inputValues[field]?.trim().length>0
      );
      if(allFieldsFilled){
        

        const { title, price } = decorations[selectedDecoration];
        dispatch(addItem({ decoration: title, price }));
  
        if (DecorationId != null) {
          dispatch(removeDecoration(decorations[DecorationId]));
        }
        dispatch(updateDecorationId(selectedDecoration));
        localStorage.setItem("person details", JSON.stringify(inputValues));
        navigate("/Decoration/Select-Cake");
      }
      else {
        alert("Please fill in all the input fields for the selected decoration");
      }
    }
    else{
      alert("Please select atleast one decoration");
    }
  };
  const handelBackButton=()=>{
    if(DecorationId!=null){
      dispatch(removeDecoration(decorations[DecorationId]));
      dispatch(updateDecorationId(null));
    }
    const { name } = itemList[0];
    navigate(`/${name}`);
  }

  return (
    <section className="content-section padding">
    <div className="container container-fluid">
      <div
        className="d-flex flex-row bd-highlight  justify-content-between align-items-center"
        // style={{ marginTop: "100px" }}
      >
        <h2>Decorations..</h2>
        <button className="bg-color p-2 px-4 bd-highlight rounded text-light">
          Rs {totalAmount}
        </button>
      </div>
      <div className="row">
        {decorations.map((decoration, index) => (
          <div
          key={index}
          className="col-lg-3 col-md-4 col-sm-6 col-6 p-2 mt-2 "
        >
            <label className="select-items p-2 d-flex justify-content-center align-items-center flex-column">
              <input
                className="select-checkbox m-2"
                type="checkbox"
                checked={selectedDecoration === index}
                onChange={() => handleCheckboxChange(index)}
              />
             <div
                  className="rounded-circle  d-flex justify-content-center align-items-center box-allignment"
                >
                <img
                  src={decoration.imgSrc}
                  alt={decoration.title}
                  className="img-fluid rounded-circle"
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>

              <div className="text-center fw-bold mt-2">
                <p>{decoration.title}</p>
                <p className="price-text">Rs.{decoration.price}/-</p>
                {selectedDecoration === index &&
                  decoration.inputFields &&
                  decoration.inputFields.map((field, i) => (
                    <div key={i} className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control w-100 border border-danger"
                        placeholder={field}
                        value={inputValues[field] || ""}
                        onChange={(event) => handleInputChange(event, field)}
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  ))}
              </div>
            </label>
          </div>
        ))}
      </div>
      <div className="d-flex flex-row bd-highlight mb-3 justify-content-center"></div>
      <div className="d-flex flex-row bd-highlight mb-3 justify-content-center">
        <button className="p-2 px-4 m-1 bd-highlight rounded text-light bg-color" onClick={handelBackButton}>
          Back
        </button>
        <button
          onClick={handleNextButtonClick}
          className="p-2 px-4 m-1 bd-highlight rounded text-light bg-color"
        >
          Next
        </button>
      </div>
    </div>
    </section>
  );
};

export default Decoration;
