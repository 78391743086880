import React from 'react'
import galleryImg1 from '../assets/storage/gallery/14.jpg'
import galleryImg2 from '../assets/storage/gallery/DSC_0929_1.jpg'
import galleryImg3 from '../assets/storage/gallery/10.jpg'
import galleryImg4 from '../assets/storage/gallery/54.jpg'
import galleryImg5 from '../assets/storage/gallery/image_50397185.jpg'
import galleryImg6 from '../assets/storage/gallery/DSC_0911_1.jpg'
import galleryImg7 from '../assets/storage/gallery/DSC_0927_1.jpg'

const Gallery = () => {
  return (
    <div>
<div>
  <section className="page-header padding">
    <div className="container">
      <div className="page-content text-center">
        <h2>Gallery</h2>
        {/* <p>Construction & Building</p> */}
      </div>
    </div>
  </section>
  <div className="work-section" id="works" style={{paddingBottom: 20}} >
    <div className="container">
      <div className="row">
        <div className="section-title">
          {/*<h2 style="padding-top: 25px;">My Works</h2>*/}
        </div>
      </div>
      <div className="row">
        <div className="work-item">
          <div className="work-item-inner">
            <img src={galleryImg1} alt="14.jpg" title="14.jpg" />
          </div>
        </div>
        <div className="work-item">
          <div className="work-item-inner">
            <img src={galleryImg2} alt="DSC_0929_1.JPG" title="DSC_0929_1.JPG" />
          </div>
        </div>
        <div className="work-item">
          <div className="work-item-inner">
            <img src={galleryImg3} alt="10.jpg" title="10.jpg" />
          </div>
        </div>
        <div className="work-item">
          <div className="work-item-inner">
            <img src={galleryImg4} alt="54.jpg" title="54.jpg" />
          </div>
        </div>
        <div className="work-item">
          <div className="work-item-inner">
            <img src={galleryImg5} alt="image_50397185.jpeg" title="image_50397185.jpeg" />
          </div>
        </div>
        <div className="work-item">
          <div className="work-item-inner">
            <img src={galleryImg6} alt="DSC_0911_1.JPG" title="DSC_0911_1.JPG" />
          </div>
        </div>
        <div className="work-item">
          <div className="work-item-inner">
            <img src={galleryImg7} alt="DSC_0927_1.JPG" title="DSC_0927_1.JPG" />
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* lightbox start */}
  <div className="lightbox">
    <div className="img-box">
      <div className="lightbox-close">×</div>
      <img className="lightbox-img" src={galleryImg1} alt="14.jpg" title="14.jpg" />
      <div className="lightbox-caption">
        <div className="lightbox-category">
        </div>
        <div className="lightbox-counter">
        </div>
      </div>
      <img className="lightbox-img" src={galleryImg2} alt="DSC_0929_1.JPG" title="DSC_0929_1.JPG" />
      <div className="lightbox-caption">
        <div className="lightbox-category">
        </div>
        <div className="lightbox-counter">
        </div>
      </div>
      <img className="lightbox-img" src={galleryImg3} alt="10.jpg" title="10.jpg" />
      <div className="lightbox-caption">
        <div className="lightbox-category">
        </div>
        <div className="lightbox-counter">
        </div>
      </div>
      <img className="lightbox-img" src={galleryImg4} alt="54.jpg" title="54.jpg" />
      <div className="lightbox-caption">
        <div className="lightbox-category">
        </div>
        <div className="lightbox-counter">
        </div>
      </div>
      <img className="lightbox-img" src={galleryImg5} alt="image_50397185.jpeg" title="image_50397185.jpeg" />
      <div className="lightbox-caption">
        <div className="lightbox-category">
        </div>
        <div className="lightbox-counter">
        </div>
      </div>
      <img className="lightbox-img" src={galleryImg6} alt="DSC_0911_1.JPG" title="DSC_0911_1.JPG" />
      <div className="lightbox-caption">
        <div className="lightbox-category">
        </div>
        <div className="lightbox-counter">
        </div>
      </div>
      <img className="lightbox-img" src={galleryImg7} alt="DSC_0927_1.JPG" title="DSC_0927_1.JPG" />
      <div className="lightbox-caption">
        <div className="lightbox-category">
        </div>
        <div className="lightbox-counter">
        </div>
      </div>
      <div className="lightbox-controls">
        <div className="prev">
          <img src="assets/img/back.html" alt="prev" />
        </div>
        <div className="next">
          <img src="assets/img/right.html" alt="next" />
        </div>
      </div>
    </div>
  </div>
  {/* lightbox end */}
  <div className="sponsor-section bg-grey">
    <div className="dots" />
    <div className="container">
      <div id="sponsor-carousel" className="sponsor-carousel owl-carousel">
      </div>
    </div>
  </div>
  {/*<div class="sponsor-section bg-grey">*/}
  {/*    <div class="dots"></div>*/}
  {/*    <div class="container">*/}
  {/*        <div id="sponsor-carousel" class="sponsor-carousel owl-carousel">*/}
  {/*            */}
  {/*        </div>*/}
  {/*    </div>*/}
  {/*</div>*/}
  {/*<div class="icon-bar">*/}
  {/*    */}
  {/*    <a href="https://wa.me/9988-343-343" class="whatsapp"><i class="fab fa-whatsapp"></i></a>*/}
  {/*    */}
  {/*    */}
  {/*    <a href="tel:9988-343-343" class="twitter"><i class="fa fa-phone"></i></a>*/}
  {/*    */}
  {/*    */}
  {/*    <a href="https://instagram.com/_tapesntales__?igshid=MzRlODBiNWFlZA==" target="_blank" class="google"><i class="fab fa-instagram"></i></a>*/}
  {/*    */}
  {/*    */}
  {/*</div>*/}
{/* index.php  29 Nov 2019 03:30:38 GMT */}
</div>


    </div>
  )
}

export default Gallery