import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addItem,
  selectTotalAmount,
  removeCake,
  updateCakeId,
} from "../../Redux/reducer";
import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";
import "./theatre.css";
// import Aos from "aos";
import "react-datepicker/dist/react-datepicker.css";
// import "aos/dist/aos.css";

import BlackForest from "../../assets/img/cakes/BlackForest_4101709996914.jpg";
import ButterScotch from "../../assets/img/cakes/Butterscotch_3801709998183.jpg";
import ChocoVanilla from "../../assets/img/cakes/choco vanilla - 3801709996969.jpg";
import ChocoChip from "../../assets/img/cakes/ChocoChip_4201709997581.jpg";
import PineApple from "../../assets/img/cakes/PineApple_3501709995823 (1).jpg";
import RabdiPista from "../../assets/img/cakes/RabdiPista almond cake_4901709997701.jpg";
import ChocoPraline from "../../assets/img/cakes/FRC14_6501709999418.jpg";
import RedVelvet from "../../assets/img/cakes/Redvelvet_5201709995772.jpg";
import SweetHeart from "../../assets/img/cakes/Sweetheart_5701709998788.jpg";
import OreoCake from "../../assets/img/cakes/oreo_cake.jpg"

const CakeSelect = () => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items = useSelector((state) => state.Item);
  const CakeId = useSelector((state) => state.item.cakeId);
  const PresentAmount = useSelector(selectTotalAmount);
  const selectedSlot = useSelector((state) => state.item.selectedSlot);
  const [cakeType, setCakeType] = useState("Eggless");
  const [totalAmount, setTotalAmount] = useState(PresentAmount);
  const [selectedCake, setSelectedCake] = useState(CakeId);


  const cakes = [
    {
      imagesrc: PineApple,
      name: "Pine Apple",
      type: "Eggless",
      price: 499,
    },
    {
      imagesrc: BlackForest,
      name: "Black Forest",
      type: "Eggless",
      price: 499,
    },
    {
      imagesrc: ButterScotch,
      name: "Butter Scotch",
      type: "Eggless",
      price: 499,
    },
    {
      imagesrc: ChocoVanilla,
      name: "Chocolate",
      type: "Eggless",
      price: 499,
    },
    {
      imagesrc: ChocoChip,
      name: "Choco Chip",
      type: "Eggless",
      price: 499,
    },
    
    {
      imagesrc: OreoCake,
      name: "Oreo Cake",
      type: "Eggless",
      price: 549,
    },
    // {
    //   imagesrc: ChocoPraline,
    //   name: "Choco Praline",
    //   type: "Eggless",
    //   price: 499,
    // },
    {
      imagesrc: RedVelvet,
      name: "Red Velvet",
      type: "Eggless",
      price: 799,
    },
    // {
    //   imagesrc: SweetHeart,
    //   name: "Sweet Heart",
    //   type: "Eggless",
    //   price: 499,
    // },

    // {
    //   imagesrc:
    //     "https://img.freepik.com/free-photo/fruit-cake-with-chocolate-chips-blueberries_176474-2965.jpg?w=740",
    //   name: "Eggless Vanilla Cake",
    //   type: "Egg",
    //   price: 499,
    // },
    // {
    //   imagesrc:
    //     "https://img.freepik.com/free-photo/fruit-cake-with-chocolate-chips-blueberries_176474-2965.jpg?w=740",
    //   name: "Eggless Red Velvet Cake",
    //   type: "Egg",
    //   price: 499,
    // },
  ];

  const filteredCakes = cakes.filter((cake) => cake.type === cakeType);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("slotselected", "false");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const isSetItemTrue = localStorage.getItem("slotselected") === "true";
    if (!isSetItemTrue || !selectedSlot) {
      navigate("/BookNow");
    }
  }, []);

  const handleSwitchChange = () => {
    setCakeType(cakeType === "Egg" ? "Eggless" : "Egg");
  };
  const handleCheckboxChange = (index) => {
    if (selectedCake === index) {
      return;
    }
    let newTotalAmount = totalAmount;
    if (selectedCake !== null) {
      newTotalAmount -= cakes[selectedCake].price;
    }

    setSelectedCake(index);
    setTotalAmount(cakes[index].price + newTotalAmount);
  };

  const handleNextButtonClick = () => {
    if (selectedCake != null) {
      const { name, price, type } = cakes[selectedCake];
      dispatch(addItem({ cake: name, price, type }));
      if (CakeId != null) {
        const selectedCakeObject = filteredCakes[CakeId];
        // console.log(selectedCakeObject,CakeId)
        dispatch(removeCake(selectedCakeObject));
      }
      dispatch(updateCakeId(selectedCake));
    }
    navigate("/Decoration/Select-Cake/Add-Ons");
  };
  const handleBackButtonClick = () => {
    if (selectedCake !== null) {
      dispatch(removeCake(cakes[selectedCake]));
      dispatch(updateCakeId(null));
    }
    navigate("/Decoration");
  };

  return (
    <div className="container">

      <div className="d-flex flex-row bd-highlight mb-3 justify-content-between my-4 align-items-center"   style={{ marginTop: "100px" }}>
        <h2>Cakes..</h2>
        <button className="bg-color p-2 px-4 bd-highlight rounded text-light">
          Rs {totalAmount}
        </button>
      </div>
      <div className="row">
        {cakes.map((cake, index) => (
          <div
            key={index}
            className="col-lg-3 col-md-6 col-6 p-2 mt-4 "
          >
            <label className="select-items p-2 d-flex justify-content-center align-items-center flex-column">
              <input
                className="select-checkbox m-2"
                type="checkbox"
                checked={selectedCake === index}
                onChange={() => handleCheckboxChange(index)}
              />
              <div className="d-flex flex-row bd-highlight mb-3 justify-content-between my-4 align-items-center">
                <div
                  className="rounded-circle  d-flex justify-content-center align-items-center box-allignment"
                >
                  <img
                    src={cake.imagesrc}
                    alt={cake.name}
                    className="rounded-circle"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              </div>
              <div className="text-center fw-bold mt-2">
                <p className="">{cake.name}</p>
                <p className="">Rs.{cake.price}/</p>
              </div>
            </label>
          </div>
        ))}
      </div>
      <div className="d-flex flex-row bd-highlight mb-3 justify-content-center">
        {/* <Link
        to="/Decoration"
          className="p-2 px-4 m-1 bd-highlight rounded text-light bg-color"
        >
          Back
        </Link>
        <Link to="/Decoration/Select-Cake/Add-Ons"
          className="p-2 px-4 m-1 bd-highlight rounded text-light bg-color"
        >
          Next
        </Link> */}
        <button
          onClick={handleBackButtonClick}
          className="p-2 px-4 m-1 bd-highlight rounded text-light bg-color"
        >
          Back
        </button>
        <button
          onClick={handleNextButtonClick}
          className="p-2 px-4 m-1 bd-highlight rounded text-light bg-color"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CakeSelect;
