import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
const Success = () => {
  const [searchParams] = useSearchParams();
  const referenceNumber = searchParams.get("reference");
const userDataObject=localStorage.getItem('details');
const data = JSON.parse(userDataObject);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/BookNow");
  };

  useEffect(() => {
    window.addEventListener("popstate", handleButtonClick);

    return () => {
      window.removeEventListener("popstate", handleButtonClick);
    };
  }, []);

  return (
    <>

      <div className="card text-center mt-5" style={{ maxWidth: "600px", margin: "10px auto" }}>
        <div className="card-body">
          <div className="rounded-circle bg-light d-flex justify-content-center align-items-center mx-auto" style={{ width: "100px", height: "100px" }}>
            <i className="checkmark" style={{ fontSize: "50px", color: "#9ABC66" }}>
              ✓
            </i>
          </div>
          <h1 className="card-title mt-4" style={{ color: "#88B04B", fontSize: "30px", fontWeight: "900" }}>
            Your Slot has been Booked
          </h1>
          <p className="card-text" style={{ color: "#404F5E", fontSize: "15px" }}>
            We received your purchase request for Payment Id: {referenceNumber}; <br /> we'll be in touch shortly!
          </p>
          <div className="mt-2">
            <h2 style={{ color: "#404F5E", fontSize: "20px", fontWeight: "bold" }}>Booking Details</h2>
            <ul className="list-group mt-1">
              {data.itemList.map((item, index) => (
                <li key={index} className="list-group-item">
                  <div>
                    <span style={{ fontWeight: "bold" }}>Item {index + 1}:</span>{" "}
                    {item.name ? (
                      item.name
                    ) : (
                      <>
                        {item.decoration && <span>Decoration: {item.decoration}</span>}
                        {item.cake && <span>Cake: {item.cake}</span>}
                        {item.LED && <span>LED: {item.LED}</span>}
                        {item.Roses && <span>Roses: {item.Roses}</span>}
                        {item.PhotoGraph && <span>PhotoGraph: {item.PhotoGraph}</span>}
                      </>
                    )}
                    - ₹{item.price}
                  </div>
                </li>
              ))}
            </ul>
            <div style={{ marginTop: "10px" }}>
              <span style={{ fontWeight: "bold" }}>Name:</span> {data.names[0].Name ? data.names[0].Name : ""}
            </div>
            <div>
              <span style={{ fontWeight: "bold" }}>Contact Number:</span> {data.contactNumber}
            </div>
            <div>
              <span style={{ fontWeight: "bold" }}>Email:</span> {data.email}
            </div>
            <div>
              <span style={{ fontWeight: "bold" }}>Date:</span>{" "}
              {new Date(data.date).toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </div>
            <div>
              <span style={{ fontWeight: "bold" }}>Pending Amount:</span> ₹{data.pendingAmount}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <p className="text-danger">Please take the ScreenShot of this page and send us via whatsapp</p>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <button type="button" className="btn btn-outline-success m-2" onClick={handleButtonClick}>
            Home
          </button>
        </div>
      </div>
    </>
  );
};

export default Success;
