import React from "react";

const Terms = () => {
  return (
    <div>
      <div>
        <section className="page-header padding">
          <div className="container">
            <div className="page-content text-center">
              <h2>Terms and Conditions</h2>
            </div>
          </div>
        </section>
        <section className="about-section padding">
          <div className="container">
            <div className="row about-wrap">
              <div className="col-lg-12 sm-padding">
                <div className="about-content wow fadeInLeft">
                  <p>
                    1. Celebrations without decorations are not allowed.
                    <br />– Explanation: Decorations are required for all
                    celebrations to create a festive atmosphere.
                  </p>
                  <p>
                    2. Ensure no damage to any decorations, balloons, lighting,
                    etc.
                    <br />– Explanation: Customers must avoid causing any damage
                    to provided decorations and items like balloons and
                    lighting.
                  </p>
                  <p>
                    3. Customers should vacate the theatre with all their
                    belongings on or before the end time.
                    <br />– Explanation: Customers must leave the theatre
                    premises along with their belongings before the scheduled
                    end time of their booking.
                  </p>
                  <p>
                    4. Guests are requested to maintain CLEANLINESS inside the
                    theatre; in case of a mess, cleaning charges will be
                    applicable.
                    <br />– Explanation: Customers are expected to keep the
                    theatre clean during their stay, and cleaning charges may
                    apply if there is a mess.
                  </p>
                  <p>
                    5. Party poppers/Cold Fire/Snow sprays or any similar kind
                    of items are strictly prohibited inside the theatre.
                    <br />– Explanation: For safety reasons, items like party
                    poppers, cold fire, snow sprays, or similar items are not
                    allowed inside the theatre.
                  </p>
                  <p>
                    6. Illegal activities such as smoking and drinking alcohol
                    are strictly prohibited.
                    <br />– Explanation: Smoking and drinking alcohol are
                    strictly forbidden within the theatre premises due to legal
                    restrictions.
                  </p>
                  <p>
                    7. We are not responsible for your personal belongings.
                    <br />– Explanation: Customers are responsible for the
                    safety of their personal belongings, and Party Theatre will
                    not be liable for any loss or theft.
                  </p>
                  <p>
                    8. Customers can’t move any properties or goods that are
                    organized under the circumstances.
                    <br />– Explanation: Customers are not allowed to move or
                    rearrange any properties or goods set up by Party Theatre
                    for the event.
                  </p>
                  <p>
                    9. The customer should carry a valid proof of ownership for
                    streaming the content and cannot stream any content
                    prohibited in India.
                    <br />– Explanation: Customers must provide proof of
                    ownership for content streaming and comply with India’s
                    content regulations.
                  </p>
                  <p>
                    10. Couples below 18 years of age are not allowed and no
                    refund will be issued.
                    <br />– Explanation: Party Theatre does not allow couples
                    below 18 years of age to use their facilities.
                  </p>
                  <p>
                    11. In case of any public holidays or festivals, ensure
                    cancellation of booking is not acceptable.
                    <br />– Explanation: Bookings made during public holidays or
                    festivals cannot be canceled.
                  </p>
                  <p>
                    12. Wi-fi facilities will be provided.
                    <br />– Explanation: Party Theatre offers Wi-fi facilities
                    for customers’ convenience.
                  </p>
                  <p>
                    13. Illegal content is prohibited; we will not entertain
                    such activities and don’t allow such customers.
                    <br />– Explanation: Party Theatre strictly prohibits the
                    use or dissemination of illegal content and reserves the
                    right to deny entry to customers engaged in such activities.
                  </p>
                  <p>
                    14. Technical Issues: In the event of technical issues
                    during the booking or while using the facilities, Party
                    Theatre will make reasonable efforts to resolve them
                    promptly. However, no liability will be assumed for any
                    disruptions caused by technical problems beyond our control.
                  </p>
                  <p>
                    15.Parking at your own risk: Party Theatre does not take
                    responsibility for any damage, theft, or loss of vehicles
                    parked on the premises. Customers are advised to park their
                    vehicles at their own risk.
                  </p>
                  <p>
                    16. Customers are not allowed to touch or change any
                    technical or non-technical items in the theatre, such as
                    screen projectors, speakers, or lights. In case of any
                    damage, the appropriate fine will be raised upon it.
                    <br />– Explanation: Customers must not touch or alter any
                    technical or non-technical equipment within the theatre, and
                    any damages will result in fines.
                  </p>
                  <p>
                    17. Customers are not allowed to carry out any property or
                    decoration items in the theatre without our permission.
                    <br />– Explanation: Customers must seek permission before
                    bringing in any external property or decoration items.
                  </p>
                  <p>
                    18. Content is not provided from our end, but you need to
                    bring your own account to log in, and the required
                    facilities will be given from our end.
                    <br />– Explanation: Party Theatre does not provide content
                    for streaming; customers must use their own accounts, and
                    necessary facilities will be provided.
                  </p>
                  <p>
                    19. Outside food is not allowed.
                    <br />– Explanation: Customers are not allowed to bring
                    outside food into the theatre.
                  </p>
                  <p>
                    20. Party Theatre completely deserves the right to cancel
                    the booking on any unforeseen instances, and the booking
                    amount will be refunded in 8 working days.
                    <br />– Explanation: Party Theatre reserves the right to
                    cancel bookings due to unforeseen circumstances, and
                    customers will receive a refund within 8 working days.
                  </p>
                  <p>
                    21. Ensuring a Safe and Respectful Environment: Our
                    Commitment to Addressing Misconduct
                  </p>
                  <p>
                    – Explanation: “Our staff members have the legitimate right
                    to raise questions and concerns regarding any cases of
                    misconduct. In the event that any form of inappropriate
                    behavior is observed, we are committed to taking decisive
                    and rigorous actions against the involved customer. This
                    approach ensures a respectful and safe environment for both
                    our staff and customers, fostering a positive and harmonious
                    atmosphere.”
                  </p>
                  <p>22. Charges Apply for Ages 3 and Above</p>
                  <p>
                    – Explanation: We would like to clarify that a fee will be
                    applicable for individuals who fall within the age group of
                    three years and above. This policy ensures that those who
                    are three years old or older will be subject to charges for
                    the services or facilities provided. We appreciate your
                    understanding and cooperation regarding this policy.
                  </p>
                  <p>
                    23. Appropriate fines will be raised in case of any damages
                    happened in the premises.
                  </p>
                  <p>
                    24.Buffer time cannot be used without permission. If buffer
                    time is taken every 10 minutes, additional hourly charges
                    will be applied.
                  </p>
                  <p>
                    – Explanation:It is not permissible to utilize buffer time
                    without obtaining prior permission. In the event that buffer
                    time is utilized at intervals of every 10 minutes, there
                    will be a penalty in the form of extra charges equivalent to
                    an additional hour’s cost. This policy is in place to ensure
                    efficient use of resources and to discourage frequent or
                    unnecessary consumption of buffer time, which could
                    potentially disrupt schedules and services.
                  </p>
                  <p>Hurry up and grab your slots...!!!!</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*<div class="sponsor-section bg-grey">*/}
        {/*    <div class="dots"></div>*/}
        {/*    <div class="container">*/}
        {/*        <div id="sponsor-carousel" class="sponsor-carousel owl-carousel">*/}
        {/*            */}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<div class="icon-bar">*/}
        {/*    */}
        {/*    <a href="https://wa.me/9988-343-343" class="whatsapp"><i class="fab fa-whatsapp"></i></a>*/}
        {/*    */}
        {/*    */}
        {/*    <a href="tel:9988-343-343" class="twitter"><i class="fa fa-phone"></i></a>*/}
        {/*    */}
        {/*    */}
        {/*    <a href="https://instagram.com/_tapesntales__?igshid=MzRlODBiNWFlZA==" target="_blank" class="google"><i class="fab fa-instagram"></i></a>*/}
        {/*    */}
        {/*    */}
        {/*</div>*/}
        {/* index.php  29 Nov 2019 03:30:38 GMT */}
      </div>
    </div>
  );
};

export default Terms;
