import React, { Fragment, useState, useEffect } from "react";
import Loader from "../components/Loader";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

const Failure = () => {
  const [isLoading, setIsLoading] = useState(false);
  // const [selectedSlotId, setSelectedSlotId] = useState(null); 
  const [error, setError] = useState(null); 
  const [searchParams] = useSearchParams();
  const slotIdNumber = searchParams.get("slot");
  const navigate= useNavigate()

  // useEffect(() => {
  //   if (slotIdNumber) {
  //     setSelectedSlotId(slotIdNumber); 
  //   }
  // }, [slotIdNumber]);

  // useEffect(() => {
  //   const cancelTokenSource = axios.CancelToken.source();

  //   const fetchData = async () => {
  //     if (selectedSlotId) {
  //       setIsLoading(true); 

  //       try {
  //         await axios.post(`${process.env.REACT_APP_BASE_URL}api/v1/slots`, {
  //           id: selectedSlotId,
  //           status: "false", 
  //         }, { cancelToken: cancelTokenSource.token });

  //         setIsLoading(false); 
  //       } catch (error) {
  //         if (!axios.isCancel(error)) {
  //           setError(error); 
  //           setIsLoading(false); 
  //         }
  //       }
  //     }
  //   };

  //   fetchData(); 

  //   return () => {
  //     cancelTokenSource.cancel(); 
  //   };
  // }, [selectedSlotId]);
  const handleButtonClick = () => {
    navigate("/BookNow");
  };

  return (
    <Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <>
        <div className="card text-center mt-5" style={{ maxWidth: "400px", margin: "100px auto" }}>
          <div className="card-body">
            <div className="rounded-circle bg-light d-flex justify-content-center align-items-center mx-auto" style={{ width: "200px", height: "200px" }}>
              <i className="checkmark" style={{ fontSize: "100px", color: "red" }}>X</i>
            </div>
            <h1 className="card-title mt-4" style={{ color: "red", fontSize: "40px", fontWeight: "900" }}>Sorry your Slot has not been Booked</h1>
            <p className="card-text" style={{ color: "#404F5E", fontSize: "20px" }}>Please Contact us for refund</p>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <button type="button" className="btn btn-outline-danger m-2" onClick={handleButtonClick}>
            Home
          </button>
        </div>
        </div>
       
        </>
        
      )}
      {error && <p>Error: {error.message}</p>} 
    </Fragment>
  );
};

export default Failure;
