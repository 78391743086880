import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  addItem,
  selectTotalAmount,
  removeAddOns,
  updateadsOnLEDId,
  updateadsOnRoseId,
  updateadsOnPhotoId
} from "../../Redux/reducer";
import "./theatre.css";
import { Link } from "react-router-dom";
// import Aos from "aos";
import "react-datepicker/dist/react-datepicker.css";
// import "aos/dist/aos.css";

import BrideLED from "../../assets/img/addOns/BR1701249953.jpeg";
import Fog from "../../assets/img/addOns/fog effect1698949248.webp";
import HBDLED from "../../assets/img/addOns/hbd letters1698949823.jpg";
import ILULED from "../../assets/img/addOns/i love u1703488435.jpg";
import LED18 from "../../assets/img/addOns/led numbers1698949779.jpg";
import PartyProps from "../../assets/img/addOns/party props1698949651.jpg";
import PhotoUnlimited from "../../assets/img/addOns/photounlimited1697195637.jpeg";
import RoseBouquet from "../../assets/img/addOns/rosebouquet1694581196.jpg";
import SingleFlower from "../../assets/img/addOns/singleflower1694581155.jpg";
import TableLight from "../../assets/img/addOns/table lighting.png"

const AddOns = () => {
  const dispatch = useDispatch();
  const itemList = useSelector((state) => state.item.itemList);


  const navigate = useNavigate();
  const AdsOnLEDId = useSelector((state) => state.item.adsOnLEDId);
  const AdsOnRoseId = useSelector((state) => state.item.adsOnRoseId);
  const AdsOnPhotoId = useSelector((state) => state.item.adsOnPhotoId);
  const selectedSlot = useSelector((state) => state.item.selectedSlot);
  const PresentAmount = useSelector(selectTotalAmount);
  const [totalAmount, setTotalAmount] = useState(0);
  const [LedtotalAmount, setLedTotalAmount] = useState(0);
  const [selectedLEDadOns, setSelectedLEDaddOns] = useState([]);
  const [selectedRose, setSelectedRose]= useState(AdsOnRoseId);
  const [selectedPhoto, setSelectedPhoto]= useState(AdsOnPhotoId);
// console.log(itemList)
const totalPayAmount = PresentAmount;
  const decorationsLED = [
    {
      imgSrc: TableLight,
      title: "Table Lightning",
      price: 299,
    },
    {
      imgSrc: ILULED,
      title: "I love u LED",
      price: 99,
    },
    {
      imgSrc: Fog,
      title: "Fog Effect",
      price: 499,
      note: "Subject to availability",
    },
    {
      imgSrc: PartyProps,
      title: "Party Props",
      price: 299,
    },
    {
      imgSrc: HBDLED,
      title: "Happy Birthday LED",
      price: 99,
    },
    {
      imgSrc: LED18,
      title: "LED Number",
      price: 99,
    },
  ];
  const Roses = [
    {
      imgSrc: RoseBouquet,
      title: "Rose Bouquet",
      price: 499,
    },
    
  ];
  const PhotoGraphs = [
    {
      imgSrc: PhotoUnlimited,
      title: "1 Reel + Photos" +  "(Cinematic / GoPro)",
      price: 499,
      enabled: false,
    },
  ];
  // const matchingIndexes = itemList.map(item => decorationsLED.findIndex(decoration => decoration.title === item.LED));
  // setSelectedLEDaddOns(matchingIndexes)



  useEffect(() => {
    if (selectedLEDadOns.length === 0) {

      const matchingIndexes = itemList.map(item => decorationsLED.findIndex(decoration => decoration.title === item.LED));
      setSelectedLEDaddOns(matchingIndexes);
    }
    const handleBeforeUnload = () => {
      localStorage.setItem("slotselected", "false");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [itemList, selectedLEDadOns]);

  useEffect(() => {
    const isSetItemTrue = localStorage.getItem("slotselected") === "true";
    if (!isSetItemTrue || !selectedSlot) {
      navigate("/BookNow");
    }
  }, []);

  const handleLEDCheckboxChange = (index) => {
    const alreadySelected = selectedLEDadOns.includes(index);
    let newSelectedLEDaddOns;
    if (alreadySelected) {
      newSelectedLEDaddOns = selectedLEDadOns.filter((item) => item !== index);
    } else {
      newSelectedLEDaddOns = [...selectedLEDadOns, index];
    }
    let newTotalAmount = 0; // Initialize newTotalAmount to 0
    newSelectedLEDaddOns.forEach((selectedIndex) => {
      // Check if the index is valid
      if (selectedIndex >= 0 && selectedIndex < decorationsLED.length) {
        newTotalAmount += decorationsLED[selectedIndex].price;
      }
    });
    setLedTotalAmount(newTotalAmount ); // Update totalAmount
    setSelectedLEDaddOns(newSelectedLEDaddOns);
  };
  
  const handleRoseCheckboxChange = (index) => {
    console.log(index)
    if (selectedRose === index) {
      return;
    }
    let newTotalAmount = totalAmount;
    if (selectedRose !== null) {
      newTotalAmount -= Roses[selectedRose].price;
    }

    setSelectedRose(index);
    setTotalAmount(Roses[index].price + newTotalAmount);
  };
  // const handlePhotoCheckboxChange = (index) => {
  //   if (selectedPhoto === index) {
  //     return;
  //   }
  //   let newTotalAmount = totalAmount;
  //   if (selectedPhoto !== null) {
  //     newTotalAmount -= PhotoGraphs[selectedPhoto].price;
  //   }

  //   setSelectedPhoto(index);
  //   setTotalAmount(PhotoGraphs[index].price + newTotalAmount);
  // };

  // console.log("selected", selectedLEDadOns);

  const handleNextButtonClick = () => {
    // console.log(selectedLEDadOns)

    selectedLEDadOns.forEach((selectedIndex) => {
      if (selectedIndex >= 0) {
        const { title, price } = decorationsLED[selectedIndex];
        const isAlreadyAdded = itemList.some(item => item.LED === title); 
        if (!isAlreadyAdded) {
          dispatch(addItem({ LED: title, price }));
        }
      }
    });
    if (selectedRose !== null) {
      const { title, price } = Roses[selectedRose];
      const isAlreadyAdded = itemList.some(item => item.Roses === title);
      if (!isAlreadyAdded) {
      dispatch(addItem({ Roses: title, price }));
      }
      dispatch(updateadsOnRoseId(selectedRose));
    }
    // if (selectedPhoto !== null) {
    //   const { title, price } = PhotoGraphs[selectedPhoto];
    //   const isAlreadyAdded = itemList.some(item => item.PhotoGraph === title);
    //   if (!isAlreadyAdded) {
    //   dispatch(addItem({ PhotoGraph: title, price }));
    //   }
      
    //   dispatch(updateadsOnPhotoId(selectedPhoto));
    // }
    navigate("/Decoration/Select-Cake/Add-Ons/Review");
  };
  const handleBackButtonClick = () => {
    if (selectedLEDadOns.length >= 0) {
      selectedLEDadOns.forEach((selectedIndex) => {
        if (selectedIndex >= 0) {
          dispatch(removeAddOns(decorationsLED[selectedIndex]));
        }
      });
    }
    if (AdsOnRoseId !== null) {
      dispatch(removeAddOns(Roses[selectedRose]));
      dispatch(updateadsOnRoseId(null));
    }
    if (AdsOnPhotoId !== null) {
      dispatch(updateadsOnPhotoId(null));
    }
    navigate("/Decoration/Select-Cake");
  };

  return (
    <div className="container">
      <div
        className="d-flex flex-row bd-highlight mb-3 justify-content-between align-items-center"
        style={{ marginTop: "100px" }}
      >
        <h2>Decorations..</h2>
        <button className="bg-color p-2 px-4 bd-highlight rounded text-light">
          Rs {totalAmount +LedtotalAmount +totalPayAmount }
        </button>
      </div>
      <div className="row">
        {decorationsLED.map((decoration, index) => (
          <div
            key={index}
            className="col-lg-3 col-md-4 col-sm-6 col-6 p-2 mt-2 "
          >
            <label className="select-items p-4 d-flex justify-content-center align-items-center flex-column">
              <input
                className="select-checkbox m-2"
                type="checkbox"
                checked={selectedLEDadOns.includes(index)}
                onChange={() => handleLEDCheckboxChange(index)}
              />
              <div
                className="rounded-circle p-2 box-allignment"
              >
                <img
                  src={decoration.imgSrc}
                  alt={decoration.title}
                  className="rounded-circle img-fluid"
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>

              <div className="text-center fw-bold mt-2">
                <p className="">{decoration.title}</p>
                <p className="price-text">Rs.{decoration.price}/-</p>
                {decoration.note && <p>{decoration.note}</p>}
              </div>
            </label>
          </div>
        ))}
      </div>
      <div className=" bd-highlight mb-3 " style={{ margin: "50px" }}>
        <h2>Roses..</h2>
      </div>
      <div className="row">
        {Roses.map((decoration, index) => (
          <div
            key={index}
            className="col-lg-3 col-md-6 col-6 p-2 mt-4 "
            // data-aos="fade-left"
            // data-aos-delay="300"
          >
            <label className="select-items p-2 d-flex justify-content-center align-items-center flex-column">
              <input
                className="select-checkbox m-2"
                type="checkbox"
                checked={selectedRose === index}
                onChange={() => handleRoseCheckboxChange(index)}
              />
              <div
                className="rounded-circle p-2 box-allignment"
               
              >
                <img
                  src={decoration.imgSrc}
                  alt={decoration.title}
                  className="rounded-circle img-fluid"
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>

              <div className="text-center fw-bold mt-2">
                <p className="">{decoration.title}</p>
                <p className="price-text">Rs.{decoration.price}/-</p>
              </div>
            </label>
          </div>
        ))}
      </div>
      {/* <div className=" bd-highlight mb-3 " style={{ margin: "50px" }}>
        <h2>PhotoGraphs..</h2>
      </div> */}
      {/* <div className="row">
        {PhotoGraphs.map((decoration, index) => (
          
          <div
            key={index}
            style={{
              opacity: decoration.enabled ? 1 : 0.3,
              pointerEvents: decoration.enabled ? 'auto' : 'none',
          }}
            className="col-lg-3 col-md-6 col-sm-12 p-2 mt-4 "
          >
            <label className="select-items p-4 d-flex justify-content-center align-items-center flex-column">
              <input
                className="select-checkbox m-2"
                type="checkbox"
                checked={selectedPhoto === index}
                onChange={() => handlePhotoCheckboxChange(index)}
              />
              <div
                className="rounded-circle p-2 box-allignment"
              >
                <img
                  src={decoration.imgSrc}
                  alt={decoration.title}
                  className="rounded-circle img-fluid"
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>

              <div className="text-center fw-bold mt-2">
                <p className="">{decoration.title}</p>
                <p className="price-text">Rs.{decoration.price}/-</p>
              </div>
            </label>
          </div>
        ))}
      </div> */}
      <div className="d-flex flex-row bd-highlight mb-3 justify-content-center">
        <button
          onClick={handleBackButtonClick}
          className="p-2 px-4 m-1 bd-highlight rounded text-light bg-color"
        >
          Back
        </button>
        <button
          onClick={handleNextButtonClick}
          className="p-2 px-4 m-1 bd-highlight rounded text-light bg-color"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AddOns;
