import React, { Fragment, useEffect, useState } from "react";
import {
  FaCalendarAlt,
  FaClock,
  FaUsers,
  FaRupeeSign,
  FaBirthdayCake,
  FaFilm,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";
import { selectTotalAmount } from "../Redux/reducer";
import axios from "axios";
// import Success from "./Sucess";
// import Razorpay from 'razorpay';
const Review = () => {
  const navigate = useNavigate();
  const [personDetails, setPersonDetails] = useState([]);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const [timer, setTimer] = useState(600);
  const [timerInterval, setTimerInterval] = useState(null);
  const selectedSlot = useSelector((state) => state.item.selectedSlot) || null;
  const date = useSelector((state) => state.item.selectedDate) || null;
  const people = useSelector((state) => state.item.selectedPeople) || 0;
  const totalAmount = useSelector(selectTotalAmount) || 0;
  const itemList = useSelector((state) => state.item.itemList) || [];
  const decoration = itemList.map((item) => item.decoration).filter(Boolean);
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("slotselected", "false");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const isSetItemTrue = localStorage.getItem("slotselected") === "true";
    if (!isSetItemTrue || !selectedSlot) {
      navigate("/BookNow");
      return;
    }
  });

  useEffect(() => {
    // Start the timer when component mounts
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
    setTimerInterval(interval);

    // Clean up the interval when component unmounts
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Redirect to booking page if timer reaches 0
    if (timer === 0) {
      clearInterval(timerInterval);
      navigate("/BookNow");
    }
  }, [timer, navigate, timerInterval]);

  const resetTimer = () => {
    clearInterval(timerInterval);
    setTimer(120); // Reset timer to 2 minutes
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
    setTimerInterval(interval);
  };

  const dateParts = date ? date.split("T")[0] : "";
  let theaterName = itemList && itemList.length > 0 ? itemList[0].name : "";

  let status = false;
  useEffect(() => {
    const storedData = localStorage.getItem("person details");

    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const names = Object.values(parsedData);
      const formattedNames = names.map((name) => ({ Name: name }));
      setPersonDetails(formattedNames);
    }
  }, []);

  const fullNameRegex = /^[a-zA-Z ]+$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const whatsappRegex = /^[0-9]{10}$/;

  const verifyPaymentAndUpdateBooking = async (
    bookingId,
    paymentDetails,
    bookingStatus,
    paymentStatus,
    successRedirect,
    failureRedirect
  ) => {
    try {
      const paymentVerificationResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/v1/payment/paymentVerfication`,
        { ...paymentDetails, id: bookingId }
      );
      console.log(paymentVerificationResponse);
      const paymentId = paymentVerificationResponse.data.data._id;
      console.log(paymentId);

      try {
        if(paymentDetails.status !== 'failed'){
          const updateBookingResponse = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/v1/booking/update-booking`,
            {
              id: bookingId,
              paymentId: paymentId,
              bookingStatus: bookingStatus,
              paymentStatus: paymentStatus,
            }
          );
          if (updateBookingResponse.status === 200) {
            navigate(`${successRedirect}?reference=${paymentId}`);
          } else {
            alert(
              "Unable to update booking details... Contact Admin"
            );
            navigate(failureRedirect);
          }
        } else {
          const updateBookingResponse = await axios.post(
            `${process.env.REACT_APP_BASE_URL}api/v1/booking/update-booking`,
            {
              id: bookingId,
              paymentId: paymentId,
              bookingStatus: 'failed',
              paymentStatus: 'failed',
            }
          );

          await axios.patch(
            `${process.env.REACT_APP_BASE_URL}api/v1/slots/updateSlot/${selectedSlot.id}`,    
            {
              bookingStatus: 'false',
            }
          ).then(
            res => {
              console.log(res)
            }
          ).catch(
            error => console.log(error)
          )
          ; 

          
          if (updateBookingResponse.status === 200) {
            navigate(failureRedirect);
          }
          alert(
            "An error occurred while updating the booking details. Please Contact us for Refund..."
          );
        }
      } catch (error) {
        console.error("Error updating booking:", error);
        alert(
          "An error occurred while updating the booking details. Please Contact us for Refund..."
        );
        navigate(failureRedirect);
      }
    } catch (error) {
      console.error("Error verifying payment:", error);
      alert("An error occurred while verifying the payment.");
    }
  };

  const handleNavigate = async () => {
    // const apisExecuted = localStorage.getItem("apisExecuted");

    if (!fullNameRegex.test(fullName.trim())) {
      alert("Please enter a valid full name.");
      return;
    }

    if (!emailRegex.test(email.trim())) {
      alert("Please enter a valid email address.");
      return;
    }

    if (!whatsappRegex.test(whatsappNumber.trim())) {
      alert("Please enter a valid 10-digit WhatsApp number.");
      return;
    }

    if (!selectedSlot) {
      alert("Please select a slot before proceeding.");
      return;
    }

    const selectedSlotId = selectedSlot.id;
    const userData = {
      id: selectedSlotId,
      status: "true",
      itemList,
      names: personDetails,
      email,
      contactNumber: whatsappNumber,
      NumberOfPeople: people,
      theaterName: theaterName,
      pendingAmount: totalAmount - process.env.REACT_APP_BASE_AMOUNT,
      date: dateParts,
    };
    localStorage.setItem("details", JSON.stringify(userData));

    let decoration = "";
    let cake = "";
    let LEDArray = [];
    let Roses = "";
    let PhotoGraph = "";

    itemList.forEach((item) => {
      if ("LED" in item) {
        if (Array.isArray(item.LED)) {
          LEDArray = LEDArray.concat(item.LED);
        } else {
          LEDArray.push(item.LED);
        }
      }
    });
    itemList.forEach((item) => {
      if ("decoration" in item) {
        decoration = item.decoration;
      }

      if ("cake" in item) {
        cake = item.cake;
      }

      if ("Roses" in item) {
        Roses = item.Roses;
      }
      if ("PhotoGraph" in item) {
        PhotoGraph = item.PhotoGraph;
      }
    });

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/v1/slots/getSlot/${selectedSlot.id}`
      );

      if (data.data.bookingStatus !== false) {
        alert("This slot is already booked.");
        navigate("/BookNow");
        return;
      }

      const {
        data: { key },
      } = await axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/getKey`);

      const {
        data: { order },
      } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/v1/orders/checkout`,
        {
          amount: process.env.REACT_APP_BASE_AMOUNT,
        }
      );

      if (!apiCalled) {
        try {
          setIsLoading(true);
          const Dbdate = `${dateParts}T00:00:00.000+00:00`;
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}api/v1/booking/save-all-bookings`,
              {
                NumberOfPeople: people,
                decoration,
                cake,
                LED: LEDArray,
                Roses,
                PhotoGraph,
                names: personDetails,
                email,
                contactNumber: whatsappNumber,
                pendingAmount: totalAmount - process.env.REACT_APP_BASE_AMOUNT,
                theaterName: theaterName,
                selectedSlotId: selectedSlotId,
                date: Dbdate,
                status: "true",
              }
            )
            .then((response) => {
              setApiCalled(true);
              if (response.status === 200) {
                const bookingId = response.data.data._id;
                var options = {
                  key, // Enter the Key ID generated from the Dashboard
                  amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                  currency: "INR",
                  name: "Party Theater", //your business name
                  description: "Test Transaction",
                  image: "https://example.com/your_logo",
                  order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                  handler: async function (res) {
                    const paymentDetails = {
                      razorpay_order_id: res.razorpay_order_id,
                      razorpay_payment_id: res.razorpay_payment_id,
                      razorpay_signature: res.razorpay_signature,
                    };
                    if(
                      res.razorpay_payment_id
                    )
                    {
                      console.log("payment success")
                      verifyPaymentAndUpdateBooking(
                        bookingId,
                        paymentDetails,
                        "confirmed",
                        "paid",
                        "/paymentSuccess",
                        `/payment-failed?slot=${selectedSlotId}`
                      );
                    }
                    else {
                      console.log("payment failed ")
                      verifyPaymentAndUpdateBooking(
                        bookingId,
                        paymentDetails,
                        "failed",
                        "failed",
                        "/payment-failed",
                        `/payment-failed?slot=${selectedSlotId}`
                      );
                    }
                    
                  },
                  prefill: {
                    name: fullName,
                    email: email,
                    contact: whatsappNumber,
                  },
                  notes: {
                    address: "Razorpay Corporate Office",
                  },
                  theme: {
                    color: "#3399cc",
                  },
                };
                var rzp1 = new window.Razorpay(options);
                rzp1.on("payment.failed", function (response) {
                  console.log("Payment failed:", response.error.data)
                  alert(`Payment failed: ${response.error.data.error.description}`);
                  const paymentDetails = {
                    razorpay_order_id: response.error.data.error.metadata.order_id,
                    razorpay_payment_id: response.error.data.error.metadata.payment_id,
                    status: "failed",
                  };
                  // const paymentDetails = {
                  //   razorpay_order_id: response.razorpay_order_id,
                  //   razorpay_payment_id: response.razorpay_payment_id,
                  //   razorpay_signature: response.razorpay_signature,
                  // };

                  verifyPaymentAndUpdateBooking(
                    bookingId,
                    paymentDetails,
                    "failed",
                    "failed",
                    "/payment-failed",
                    `/payment-failed?slot=${selectedSlotId}`
                  );
                });
                rzp1.open();
              } else {
                console.error(
                  "An error occurred while saving booking details:",
                  response
                );
                alert("Oops! Something went wrong. Please try again later.");
                navigate("/BookNow");
                return;
              }
            })
            .catch((error) => {
              setApiCalled(true);
              console.error(
                "An error occurred while saving order and user details:",
                error
              );
              alert("Oops! Something went wrong. Please try again later.");
              navigate("/BookNow");
              return;
            })
            .finally(() => {
              setApiCalled(true);
              setIsLoading(false);
            });
        } catch (error) {
          setApiCalled(true);
          console.error(
            "An error occurred while saving order and user details:",
            error
          );
          alert("Oops! Something went wrong. Please try again later.");
          navigate("/BookNow");
          return;
        } finally {
          setApiCalled(true);
        }
      }
    } catch (error) {
      alert(
        "An error occurred while processing your payment. Please try again later."
      );
      navigate("/BookNow");
      return;
    }
  };

  return (
    <Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <section className="testimonial-section bg-grey padding">
          <div className="dots" />
          <div className="container">
            <div
              className="row bg-light border border-warning p-2"
              style={{ borderRadius: "20px" }}
            >
              <div
                className="col-12 text-center mb-3"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: "1000",
                }}
              >
                <p className="text-danger">
                  Please complete the payment within {Math.floor(timer / 60)}:
                  {timer % 60} minutes
                </p>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div>
                  <FaFilm className="mr-2" />{" "}
                  {/* Use FaFilm for cinema/theater icon */}
                  <p className="bg-light p-3 d-inline-block">
                    {theaterName ? theaterName : "N/A"}
                  </p>
                </div>
                <div>
                  <FaCalendarAlt className="mr-2" />
                  <p className="bg-light p-3 d-inline-block">
                    {date ? date.toString() : ""}
                  </p>
                </div>

                <div>
                  <FaClock className="mr-2" />
                  <p className="w-auto bg-light p-3 d-inline-block">
                    {selectedSlot ? selectedSlot.value : ""}
                  </p>
                </div>

                <div>
                  <FaUsers className="mr-2" />
                  <p className="w-auto bg-light p-3 d-inline-block">{people}</p>
                </div>

                <div>
                  <FaRupeeSign className="mr-2" />
                  <p className="w-auto bg-light p-3 d-inline-block">
                    {totalAmount}
                  </p>
                </div>

                <div>
                  <FaBirthdayCake className="mr-2" />
                  <p className="w-auto bg-light p-3 d-inline-block">
                    {decoration}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control w-100"
                    placeholder="Your Full Name"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    required
                  />
                </div>

                <div className="input-group mb-3">
                  <input
                    type="email"
                    className="form-control w-100"
                    placeholder="Your Email Address"
                    aria-label="Recipient's email"
                    aria-describedby="basic-addon2"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div className="input-group mb-3">
                  <input
                    type="tel"
                    className="form-control w-100"
                    placeholder="Your WhatsApp Number"
                    aria-label="Recipient's WhatsApp number"
                    aria-describedby="basic-addon2"
                    value={whatsappNumber}
                    onChange={(e) => setWhatsappNumber(e.target.value)}
                    required
                  />
                </div>
                <p>
                  We collect an advance amount of ₹ 700 + convenience fee (₹50)
                  to book the slot. Remaining bill will be...
                </p>
                <button
                  type="button"
                  className="btn btn-warning"
                  disabled={apiCalled}
                  onClick={handleNavigate}
                >
                  Proceed to Pay Advance
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default Review;
