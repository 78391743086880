import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <section className="widget-section padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6 sm-padding">
              <div className="widget-content">
                {/* <a href="index.html"> */}
                <Link to="/">
                  <h2 className="text-white">
                    Party Theatre : Private Theaters for Celebrations in
                    Hyderabad
                  </h2>
                </Link>
                {/* </a> */}
                <p className="text-white">
                  Choose the occasion, give us the requirements and leave it to
                  us. We will deliver the best.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 sm-padding">
              <div className="widget-content ">
                <h4>Quick Links</h4>
                <ul className="widget-links">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/aboutUs">About Us</Link>
                  </li>
                  <li>
                    <Link to="/Terms">Terms &amp; Conditions</Link>
                  </li>
                  <li>
                    <Link to="/PrivacyPolicy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/RefundPolicy">Refund Policy</Link>
                  </li>
                  <li>
                    <Link to="/Gallery">Gallery</Link>
                  </li>
                  {/* <li>
                    <Link to="/Services">Services</Link>
                  </li> */}
                  <li>
                    <Link to="/ContactUs">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 sm-padding">
              <div className="widget-content">
                <h4>Contact Us</h4>
                <p className="text-white">
                  Beside Cairo Box Cricket, Hno 2-23/1, Surya Nagar, Uppal,
                  Hyderabad, Telangana 500039
                </p>
                <span>
                  <a href="mailto: support@apartytheatre.com">
                    support@apartytheatre.com
                  </a>
                </span>
                <span>
                  <a href="tel:8886660841">88866 60842</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer-section align-center">
        <div className="container">
          <p>
            Designed and Developed by{" "}
            <a href=""> FusionCoreLabs</a>
          </p>
        </div>
      </footer>
      <a data-scroll href="#header" id="scroll-to-top">
        <i className="arrow_carrot-up" />
      </a>
    </div>
  );
};

export default Footer;
