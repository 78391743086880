import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  itemList: [],
  totalAmount: 0,
  decorationId: null,
  cakeId: null,
  adsOnLEDId: null,
  adsOnRoseId:null,
  adsOnPhotoId:null,
  selectedSlot: null,
  selectedDate: null,
  selectedPeople: null,
};

const itemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    addItem(state, action) {
      const itemToAdd = action.payload;
      const updatedItemList = [...state.itemList, { ...itemToAdd }];
      const updatedTotalAmount = state.totalAmount + itemToAdd.price;
      return {
        ...state,
        itemList: updatedItemList,
        totalAmount: updatedTotalAmount,
      };
    },
    removeDecoration(state, action) {
      const itemToRemove = action.payload;
      const itemIndex = state.itemList.findIndex(
        (item) =>
          item.decoration === itemToRemove.title && item.price === itemToRemove.price
      );
      console.log(itemIndex)
      if (itemIndex !== -1) {
        const updatedItemList = [
          ...state.itemList.slice(0, itemIndex),
          ...state.itemList.slice(itemIndex + 1),
        ];
        const updatedTotalAmount = state.totalAmount - itemToRemove.price;
        return {
          ...state,
          itemList: updatedItemList,
          totalAmount: updatedTotalAmount,
        };
      }
    },
    removeCake(state, action) {
      const itemToRemove = action.payload;
      const itemIndex = state.itemList.findIndex(
        (item) =>
          item.cake === itemToRemove.name && item.price === itemToRemove.price
      );
      console.log(itemIndex);
      if (itemIndex !== -1) {
        const updatedItemList = [
          ...state.itemList.slice(0, itemIndex),
          ...state.itemList.slice(itemIndex + 1),
        ];
        const updatedTotalAmount = state.totalAmount - itemToRemove.price;
        return {
          ...state,
          itemList: updatedItemList,
          totalAmount: updatedTotalAmount,
        };
      }
    },
    removeAddOns(state, action) {
      const itemToRemove = action.payload;
      console.log(itemToRemove)
      const itemType = Object.keys(itemToRemove)[1]; 
      const itemTitle = itemToRemove[itemType]; 
      const itemPrice = itemToRemove.price; 
    
      const itemIndex = state.itemList.findIndex(
        (item) => Object.keys(item)[0]=== "LED" && item.price === itemPrice
      );
      // console.log(itemIndex)
    
      if (itemIndex !== -1) {
        const updatedItemList = [
          ...state.itemList.slice(0, itemIndex),
          ...state.itemList.slice(itemIndex + 1),
        ];
        const updatedTotalAmount = state.totalAmount - itemPrice;
        return {
          ...state,
          itemList: updatedItemList,
          totalAmount: updatedTotalAmount,
        };
      }
      return state;
    },
    updateTotalAmount(state, action) {
      const difference = action.payload - state.totalAmount;
      const updatedItemList = state.itemList.map((item) => ({
        ...item,
        price: item.price + difference,
      }));
      return {
        ...state,
        itemList: updatedItemList,
        totalAmount: action.payload,
      };
    },
    updateDecorationId(state, action) {
      state.decorationId = action.payload;
    },
    updateCakeId(state, action) {
      state.cakeId = action.payload;
    },
    updateadsOnLEDId(state, action) {
      state.adsOnLEDId = action.payload;
    },
    updateadsOnRoseId(state, action) {
      state.adsOnRoseId = action.payload;
    },
    updateadsOnPhotoId(state, action) {
      state.adsOnPhotoId = action.payload;
    },
    updateSelectedSlot(state, action) {
      state.selectedSlot = action.payload;
    },
    updateDate(state, action) {
      state.selectedDate = action.payload;
    },
    updateSelectedPeople(state, action) {
      state.selectedPeople = action.payload;
    },
    resetState(state) {
      return initialState;
    },
  },
});

export const {
  addItem,
  removeDecoration,
  removeCake,
  removeAddOns,
  updateDecorationId,
  updateCakeId,
  updateadsOnLEDId,
  updateadsOnRoseId,
  updateadsOnPhotoId,
  updateSelectedSlot,
  updateDate,
  updateSelectedPeople,
  updateTotalAmount,
  resetState,
} = itemSlice.actions;
export const selectTotalAmount = (state) => state.item.totalAmount;
export const selectDecorationId = (state) => state.decorationId;
export default itemSlice.reducer;
