import React from 'react'
import '../assets/css/venobox/venobox.css'
import '../assets/css/fontawesome.min.css'
import '../assets/css/themify-icons.css'
import '../assets/css/elegant-line-icons.css'
import '../assets/css/elegant-font-icons.css'
import '../assets/css/flaticon.css'
import '../assets/css/animate.min.css'
import '../assets/css/bootstrap.min.css'
import '../assets/css/slick.css'
import '../assets/css/slider.css'
import '../assets/css/odometer.min.css'
import '../assets/css/owl.carousel.css'
import '../assets/css/main.css'
import '../assets/css/date-picker.css'
import '../assets/css/responsive.css'
// import '../assets/code.jquery.com/ui/1.13.0/themes/base/jquery-ui.css'
import aboutUs from '../assets/storage/ckeditor/Aboutus_image_1698952310.png'

const AboutUs = () => {
  return (
    <div style={{ textAlign: "justify" }}>
      <section className="page-header padding">
        <div className="container">
          <div className="page-content text-center">
            <h2>About Us</h2>
          </div>
        </div>
      </section>
      <section className="about-section padding">
        <div className="container" style={{ textAlign: "center" }}>
          <div className="row about-wrap">
            <div className="col-lg-12 sm-padding">
              <div className="about-content wow fadeInLeft">
                <div style={{ display: "inline-block", textAlign: "center", maxWidth: "800px", margin: "0 auto" }}>
                  <h2>Welcome to Party Theatre</h2>
                  <div style={{ maxWidth: "387px", margin: "0 auto 20px" }}>
                    <img
                      alt="About Us"
                      src={aboutUs}
                      style={{ width: "100%", height: "auto", display: "block" }}
                    />
                  </div>
                  <p>
                    Party Theatre, a premier destination for exclusive events and immersive entertainment experiences. Our visionary approach combines the nostalgia of storytelling with cutting-edge technology, redefining the essence of premium private theater and event celebrations.
                  </p>
                  <p>
                    At Party Theatre, we craft unforgettable moments that blend modern sophistication with the charm of timeless narratives. With a dedicated focus on creating an unparalleled cinematic journey, our state-of-the-art theater facilities provide the perfect setting for bespoke events, from intimate gatherings to extravagant celebrations.
                  </p>
                  <p>
                    Our meticulously designed private theater and event celebration space offer a haven for individuals and organizations seeking a unique and luxurious venue. Immerse yourself in our meticulously curated ambiance, where each detail is meticulously tailored to heighten your senses and transport you to a world of enchantment.
                  </p>
                  <p>
                    Experience the allure of Party Theatre, where every event becomes an extraordinary tale to be cherished for a lifetime. Join us on a journey that transcends the ordinary, as we redefine the art of entertainment and celebration.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};




export default AboutUs