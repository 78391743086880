import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutUs from "./Pages/AboutUs";
import BookNow from "./Pages/BookNow";
import ContactUs from "./Pages/ContactUs";
import Gallery from "./Pages/Gallery";
import Home from "./Pages/Home/Home";
import Layout from "./Pages/Layout/Layout";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import RefundPolicy from "./Pages/RefundPolicy";
import Services from "./Pages/Services";
import Terms from "./Pages/Terms";
import  GoldenTheatre from "./Pages/Theatres/GoldenTheatre";
import SilverTheater from "./Pages/Theatres/SilverTheater";
import Decoration from "./Pages/Theatres/Decoration";
import CakeSelect from "./Pages/Theatres/CakeSelection";
import AddOns from "./Pages/Theatres/AddOns";
import Review from "./Pages/Review";
import Success from './Pages/Sucess';
import ScrollToTop from './components/ScrollToTop';
import Failure from './Pages/Failure';
function App() {

  useEffect(() => {
    const confirmNavigation = (e) => {
      e.preventDefault();
      e.returnValue = ''; 
      return ''; 
    };
    window.addEventListener('beforeunload', confirmNavigation);
    return () => window.removeEventListener('beforeunload', confirmNavigation);
  }, []);
  return (
    <BrowserRouter>
        <ScrollToTop /> 

      <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home/>}/>
        <Route path="/paymentSuccess" element={<Success/>}/>
        <Route path="/payment-failed" element={<Failure/>}/>
        <Route path="aboutUs" element={<AboutUs/>}/>
        <Route path="Gallery" element={<Gallery/>}/>
        <Route path="RefundPolicy" element={<RefundPolicy/>}/>
        <Route path="ContactUs" element={<ContactUs/>}/>
        <Route path="PrivacyPolicy" element={<PrivacyPolicy/>}/>
        <Route path="Services" element={<Services/>}/>
        <Route path="Terms" element={<Terms/>}/>
        <Route path="BookNow" element={<BookNow/>}/>
        <Route path="Golden_Theatre" element={<GoldenTheatre/>}/>
        <Route path="Silver_Theatre" element={<SilverTheater/>}/>
        <Route path="Decoration" element={<Decoration/>}/>
        <Route path="Decoration/Select-Cake" element={<CakeSelect/>}/>
        <Route path="Decoration/Select-Cake/Add-Ons" element={<AddOns/>}/>
        <Route path="Decoration/Select-Cake/Add-Ons/Review" element={<Review/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;


