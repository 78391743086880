import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addItem, resetState,updateDate } from '../Redux/reducer';
import { useNavigate } from 'react-router-dom';
import GoldenTheater from '../assets/storage/theaters/dd0acd5a-a326-4989-a0ea-d6f7cc3fb5fc1703344234.jpg';
import SilverTheater from '../assets/storage/theaters/theater5.jpg';
import FetchData from '../components/FetchDataComponent';
import DatePicker from 'react-datepicker';
import Loader from '../components/Loader';
const BookNow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const itemList = useSelector((state) => state.item.itemList);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [fetchSlot, setFetchSlot] = useState(`${process.env.REACT_APP_BASE_URL}api/v1/slots/${selectedDate}`);
  const { loading, data, error } = FetchData({ link: fetchSlot });

  useEffect(() => {
    dispatch(resetState());
  }, []);

  useEffect(() => {
    setFetchSlot(`${process.env.REACT_APP_BASE_URL}api/v1/slots/${selectedDate}`);
  }, [selectedDate]);

  useEffect(() => {
    localStorage.clear(); 
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleNavigation = (link, name, price) => {
    if(selectedDate){
      // console.log(price)
      dispatch(addItem({ name, price }));
      dispatch(updateDate(selectedDate.toISOString()));
      localStorage.setItem('slotselected', 'true');
      navigate(link);
    }
  };


  if (loading) {
    return <Loader />;
  }

  if (error) {
    console.log(error);
    return <div className=''>Error: {error.message}</div>;
  }

  const currentTime = new Date();
const availableGoldSlots = data?.data?.filter(
  (slot) => slot.theater === "gold" && !slot.bookingStatus && new Date(slot.endTime) > currentTime
).length || 0;

const availableSilverSlots = data?.data?.filter(
  (slot) => slot.theater === "silver" && !slot.bookingStatus && new Date(slot.endTime) > currentTime
).length || 0;

  const theatersData = [
   
    {
      image: SilverTheater,
      title: 'Silver Theatre',
      price:  parseInt(process.env.REACT_APP_SILVER_THEATER,10),
      link: '/Silver_Theatre',
      name: 'Silver_Theatre',
      Description: '₹999 for 1-4 people',
      slots: availableSilverSlots,
      date: selectedDate,
      capacity: '1 - 5 People',
    },
    {
      image: GoldenTheater,
      title: 'Golden theatre(couple)',
      price: parseInt(process.env.REACT_APP_GOLDEN_THEATER,10),
      link: '/Golden_Theatre',
      name: 'Golden_Theatre',
      Description: '₹1299 For 1-5 ',
      slots: availableGoldSlots,
      date: selectedDate,
      capacity: '1 - 8 People',
    },
  ];

  return (
    <div>
      <section className="padding-20" style={{ backgroundImage: 'url(img/bg-dots.html)' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <div className="wrapper box-shadow">
                <form action="#">
                  <div className="datepickerdiv">
                    <label htmlFor="true" className="label-text">
                      Check Slot Availability
                    </label>
                    <div className="input-group date">
                      <DatePicker
                        className="form-control datepicker"
                        selected={selectedDate}
                        onChange={handleDateChange}
                        minDate={new Date()}
                        dateFormat="MMMM d, yyyy"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      </section>
      <section className="blog-section padding-20">
        <div className="container">
          <div className="row">
            {theatersData.map((theater, index) => (
              <div className="col-sm-6 padding-15" key={index} style={{ display: 'flex' }}>
                <div className="d-flex h-100 flex-column box-shadow" style={{ flex: 1 }}>
                  <div className="blog-item">
                    <div className="blog-thumb">
                      <img src={theater.image} title={theater.title} alt={theater.title} height={400}/>
                      <span className="category">
                        <p>{theater.name}</p>
                      </span>
                    </div>
                    <div className="blog-content">
                      <p className="text-dark">{theater.Description}</p>
                      <div>
                        <h3>{theater.slots} Slots Available on {theater.date.toLocaleDateString()}</h3>
                      </div>
                      {/* <p className="read-more" onClick={() => handleNavigation(theater.link, theater.name, theater.price)}>
                        Check Slots
                      </p> */}
                      <div className="input-group m-2"  >
                          <button
                            className="default-btn"
                            onClick={() => handleNavigation(theater.link, theater.name, theater.price)}
                          >
                            Check Slot
                          </button>
                        </div>
                      <p className="text-dark">{theater.capacity}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default BookNow;
