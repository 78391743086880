import React from "react";
import logo from "../../assets/img/logo.jpg";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div>
      <header className="header">
        <div className="primary-header">
          <div className="container">
            <div className="primary-header-inner">
              <div className="header-logo">
                <Link to="/">
                  <img src={logo} alt="logo" className="img-fluid logo-img" />
                </Link>
              </div>
              <div className="header-menu-wrap">
                <ul className="dl-menu">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/aboutUs">About</Link>
                  </li>
                  <li>
                    <Link to="/Gallery">Gallery</Link>
                  </li>
                  <li>
                    <Link to="/RefundPolicy">Refund Policy</Link>
                  </li>
                  <li>
                    <Link to="/ContactUs">Contact</Link>
                  </li>
                </ul>
              </div>
              <div className="header-right">
                <Link className="menu-btn" to="/BookNow">
                  Book Now
                </Link>
                <div style={{ paddingRight: "20px", paddingBottom: "10px" }}>
                  <Link
                    to="https://wa.me/+918886660842"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/598px-WhatsApp.svg.png"
                      alt="WhatsApp"
                      className="whatsapp-logo"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <style jsx>{`
        .logo-img {
          width: 70px;
          padding: 2px;
        }
        .menu-btn {
          font-size: 16px; /* Adjust as needed */
        }
        @media (max-width: 400px) {
          .logo-img {
            width: 50px;
          }
          .menu-btn {
            width: 150px;
            height:50px;
          }
            .whatsapp-logo{
            width:50px
            }
        }
      `}</style>
    </div>
  );
};

export default Header;
