import React from 'react'

const ContactUs = () => {
  return (
    <div>

<div>
  <div className="mapouter">
  {/* <iframe
    width="100%"
    height="600"
    frameBorder="0"
    marginHeight="0"
    marginWidth="0"
    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Party%20Theater,%20Surya%20nagar,%20uppal+(A%20Party%20Theatre)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
  >
    <a href="https://www.gps.ie/">gps tracker sport</a>
  </iframe> */}
    <iframe  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Party%20Theater,%20Surya%20nagar,%20uppal+(A%20Party%20Theatre)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="100%" height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" /></div>
  <section className="contact-section bg-grey padding">
    <div className="dots" />
    <div className="container">
      <div className="contact-wrap d-flex align-items-center row">
        <div className="col-md-6 padding-15">
          <div className="contact-info">
            <h2 className="text-dark">Get in touch with us &amp; <br />send us message today!</h2>
            <h4> Beside Cairo Box Cricket, Hno 2-23/1, Surya Nagar, Uppal,
                  Hyderabad, Telangana 500039</h4>
            <h4><span>Email:</span> support@apartytheatre.com <br /> <span>Phone:</span> 8886660842 <br />
            </h4></div>
        </div>
        <div className="col-md-6 padding-15">
          <div className="contact-form">
            <form className="form-horizontal" method="POST" action="" id="ajax_form"><input type="hidden" name="_token" defaultValue="Qbak6PCDkyyU6W9STg9JhkwDwWC0KJrharnEoVWV" />
              <div className="form-group colum-row row">
                <div className="col-sm-6">
                  <input className="form-control" type="text" name="name" id="name" placeholder="Name" required />
                </div>
                <div className="col-sm-6">
                  <input className="form-control" type="email" name="email" id="email" placeholder="Email" required />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-12">
                  <textarea className="form-control message" name="message" id="message" placeholder="Message" cols={30} rows={5} required defaultValue={""} />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-12">
                  <button id="submit" className="default-btn" type="submit">Send Message</button>
                </div>
              </div>
              <div id="form-messages" className="alert" role="alert" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*<div class="sponsor-section bg-grey">*/}
  {/*    <div class="dots"></div>*/}
  {/*    <div class="container">*/}
  {/*        <div id="sponsor-carousel" class="sponsor-carousel owl-carousel">*/}
  {/*            */}
  {/*        </div>*/}
  {/*    </div>*/}
  {/*</div>*/}
  {/*<div class="icon-bar">*/}
  {/*    */}
  {/*    <a href="https://wa.me/9988-343-343" class="whatsapp"><i class="fab fa-whatsapp"></i></a>*/}
  {/*    */}
  {/*    */}
  {/*    <a href="tel:9988-343-343" class="twitter"><i class="fa fa-phone"></i></a>*/}
  {/*    */}
  {/*    */}
  {/*    <a href="https://instagram.com/_tapesntales__?igshid=MzRlODBiNWFlZA==" target="_blank" class="google"><i class="fab fa-instagram"></i></a>*/}
  {/*    */}
  {/*    */}
  {/*</div>*/}
 {/* index.php  29 Nov 2019 03:30:38 GMT */}
</div>


    </div>
  )
}

export default ContactUs