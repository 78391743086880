import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div>

<div>
  <section className="page-header padding">
    <div className="container">
      <div className="page-content text-center">
        <h2>Privacy Policy</h2>
      </div>
    </div>
  </section>
  <section className="about-section padding">
    <div className="container">
      <div className="row about-wrap">
        <div className="col-lg-12 sm-padding">
          <div className="about-content wow fadeInLeft">
            <div>
              <h3 className="text-white">Privacy Policy</h3>
              <p>This privacy policy sets out how A Party Theatre uses and protects any information that you give A Party Theatre when you use this website.</p>
              <p>A Party Theatre is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, and then you can be assured that it will only be used in accordance with this privacy statement.</p>
              <p>A Party Theatre may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.</p>
            </div>
            <div>
              <h4 className="text-white">We may collect the following information:</h4>
              <ul>
                <li>Name and job title</li>
                <li>Contact information including email address</li>
                <li>Demographic information such as postcode, preferences and interests</li>
                <li>Other information relevant to customer surveys and/or offers</li>
              </ul>
            </div>
            <div>
              <h4 className="text-white">What we do with the information we gather</h4>
              <p>We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</p>
              <ul>
                <li>Internal record keeping.</li>
                <li>We may use the information to improve our products and services.</li>
                <li>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</li>
                <li>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.</li>
                <li>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure we have put in suitable measures.</li>
              </ul>
            </div>
            <div>
              <h4>Controlling your personal information</h4>
              <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>
              <ul>
                <li>whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes</li>
                <li>if you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at support@apartytheatre.com</li>
              </ul>
              <p>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.</p>
              <p>If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*<div class="sponsor-section bg-grey">*/}
  {/*    <div class="dots"></div>*/}
  {/*    <div class="container">*/}
  {/*        <div id="sponsor-carousel" class="sponsor-carousel owl-carousel">*/}
  {/*            */}
  {/*        </div>*/}
  {/*    </div>*/}
  {/*</div>*/}
  {/*<div class="icon-bar">*/}
  {/*    */}
  {/*    <a href="https://wa.me/9988-343-343" class="whatsapp"><i class="fab fa-whatsapp"></i></a>*/}
  {/*    */}
  {/*    */}
  {/*    <a href="tel:9988-343-343" class="twitter"><i class="fa fa-phone"></i></a>*/}
  {/*    */}
  {/*    */}
  {/*    <a href="https://instagram.com/_tapesntales__?igshid=MzRlODBiNWFlZA==" target="_blank" class="google"><i class="fab fa-instagram"></i></a>*/}
  {/*    */}
  {/*    */}
  {/*</div>*/}
{/* index.php  29 Nov 2019 03:30:38 GMT */}
</div>


    </div>
  )
}

export default PrivacyPolicy