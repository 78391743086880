import React from 'react'

const Services = () => {
  return (
    <div>

<div>

  <section className="page-header padding">
    <div className="container">
      <div className="page-content text-center">
        <h2>Our Services</h2>
      </div>
    </div>
  </section>
  <section className="service-section bg-grey padding">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div id="service-carousel" className="service-carousel box-shadow owl-carousel">
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* TESTIMONIAL SECTION STARTS */}
  <section className="testimonial-section bg-grey padding">
    <div className="dots" />
    <div className="container">
      <div className="section-heading text-center mb-40 wow fadeInUp" data-wow-delay="100ms">
        <span>Testimonial</span>
        <h2>What people says</h2>
      </div>
      <div id="testimonial-carousel" className="testimonial-carousel box-shadow owl-carousel">
      </div>
    </div>
  </section>
  {/* TESTIMONIAL SECTION ENDs */}
  {/*<div class="sponsor-section bg-grey">*/}
  {/*    <div class="dots"></div>*/}
  {/*    <div class="container">*/}
  {/*        <div id="sponsor-carousel" class="sponsor-carousel owl-carousel">*/}
  {/*            */}
  {/*        </div>*/}
  {/*    </div>*/}
  {/*</div>*/}
  {/*<div class="icon-bar">*/}
  {/*    */}
  {/*    <a href="https://wa.me/9988-343-343" class="whatsapp"><i class="fab fa-whatsapp"></i></a>*/}
  {/*    */}
  {/*    */}
  {/*    <a href="tel:9988-343-343" class="twitter"><i class="fa fa-phone"></i></a>*/}
  {/*    */}
  {/*    */}
  {/*    <a href="https://instagram.com/_tapesntales__?igshid=MzRlODBiNWFlZA==" target="_blank" class="google"><i class="fab fa-instagram"></i></a>*/}
  {/*    */}
  {/*    */}
  {/*</div>*/}
 {/* index.php  29 Nov 2019 03:30:38 GMT */}
</div>



    </div>
  )
}

export default Services